.container {
    width: 450px;
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    padding: 40px;
    opacity: 0.5;
}

.container h1 {
    text-align: center;
    font-size: 30px;
    color: #ffff;
}

.container .input-field {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 30px auto;
}

.input-field input {
    width: 100%;
    height: 100%;
    background: transparent;
    outline: none;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    font-size: 16px;
    color: #fff;
    padding: 20px 45px 20px 25px;
}

.input-field input::placeholder {
    color: #fff;
}

.input-field .icon {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #fff;
}

.container .recall-forget {
    display: flex;
    justify-content: space-between;
    font-size: 14.5px;
    margin: -15px 0 15px;
}

.recall-forget label input {
    accent-color: #fff;
    margin-right: 4px;
}

.recall-forget a {
    color: #fff;
    text-decoration: none;
}

.recall-forget a:hover {
    text-decoration: underline;
}

.container button {
    width: 100%;
    height: 45px;
    background: #fff;
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-size: 16px;
    color: #333;
    font-weight: 700;
    text-shadow: none;
}

.container .signup-link {
    font-size: 14.5px;
    text-align: center;
    margin: 20px 0 15px;
}

.signup-link p a {
    color: #fff;
    text-decoration: none;
    font-weight: 600;
}

.signup-link p a:hover {
    text-decoration: underline;
}